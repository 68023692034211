import React from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { useTranslation } from 'next-i18next'
import { ButtonText, Headline2 } from 'stories/elements/Typography/Text'
import BecomeKnowerFrameWebp from 'assets/homepage/become-knower-frame.webp'
import SmallBecomeKnowerFrameWebp from 'assets/homepage/small-become-knower-frame.webp'
import VideoPlayer from 'components/modules/Players/VideoPlayer'
import { becomeKnowerRoute } from 'lib/constants/routes'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import NextImage from 'components/elements/NextImage'
import { Language } from 'interfaces/Language'
import { getBecomeKnowerVideo } from 'lib/constants/homepage'
import Stack from 'components/elements/Stack'
import DynamicButton from 'components/modules/LandingPage/DynamicButton'

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${theme.colors.snowWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        height: fit-content;
    }
`

const Card = styled.div`
    height: 80vh;
    width: 90%;

    @media (max-width: 1250px) {
        width: 100%;
        border-radius: 0px;
        height: 100%;
    }
    border-radius: ${theme.borderRadius.huge};
    background-color: ${theme.colors.sunlightYellow};
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding: 0 10%;
    .sm-button {
        display: none;
    }

    @media (min-width: 1680px) {
        h2 {
            width: 80%;
        }
    }
    @media (max-width: 1440px) {
        padding: 0 5%;
    }
    @media (max-width: 1250px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15%;
        h2,
        .md-button {
            width: 100vw;
            padding: 0 10%;
            position: absolute;
            top: 60px;
            left: 0;
            text-align: center;
        }

        .md-button {
            display: none;
        }
    }

    @media (max-width: 750px) {
        height: 800px;
        grid-template-columns: 1fr;
        h2 {
            width: 100%;
            left: 15px;
            top: 15px;
            text-align: left;
        }
    }
`

const PhoneWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    position: relative;

    .front {
        z-index: ${theme.zIndex.playerIcons};
        position: relative;
        left: 165px;
        bottom: 335px;
    }
    .video {
        position: relative;
        left: 250px;
        top: 250px;
    }
    @media (max-width: 1250px) {
        align-items: center;
        place-content: center;
        .front {
            display: flex;
            justify-content: center;
            position: relative;
            bottom: 240px;
            left: 0px;
        }
        .video {
            display: flex;
            justify-content: center;
            left: 10px;
            top: 350px;
        }
    }
    @media (max-width: 750px) {
        .back {
            position: relative;
            z-index: ${theme.zIndex.playerIcons};
            left: 0px;
            bottom: 350px;
            width: 343px;
            height: 342px;
        }
        .video {
            all: unset;
            position: relative;
            top: 150px;
            left: 50px;
        }
    }
`

interface Props {
    isMobile?: boolean
    contentLanguageCode: Language
}

const BecomeKnowerComponent = ({ isMobile, contentLanguageCode }: Props) => {
    const { t } = useTranslation('homepage')
    const md = useMedia(deviceSize.md)
    const becomeKnowerVideo = getBecomeKnowerVideo(contentLanguageCode)

    if (!becomeKnowerVideo) return null

    return (
        <Container>
            <Card>
                <div>
                    <Stack gutter={10}>
                        <Headline2 color={theme.colors.accentBlue} marginBottom={isMobile ? 0 : 50}>
                            {t('homepage/becomeKnowerHeader')}
                        </Headline2>
                    </Stack>

                    <div className="md-button">
                        <DynamicButton
                            target="_blank"
                            href={becomeKnowerRoute}
                            width={325}
                            height={50}
                            color={theme.colors.white}
                            borderRadius={theme.borderRadius.normal}
                        >
                            <ButtonText color={theme.colors.accentBlue}>{t('homepage/becomeKnowerCTAButton')}</ButtonText>
                        </DynamicButton>
                    </div>
                </div>
                <PhoneWrapper>
                    <div className="video">
                        <VideoPlayer
                            height={md ? 438 : 500}
                            width={md ? 245 : 285}
                            autoPlay
                            loop
                            muted
                            muteable
                            border="6px solid #aab0ab"
                            objectCover
                            sourceType="video/mp4"
                            source={becomeKnowerVideo}
                        />
                    </div>
                    {isMobile ? (
                        <div className="back">
                            <NextImage
                                src={SmallBecomeKnowerFrameWebp.src}
                                alt="become knower frame"
                                height={SmallBecomeKnowerFrameWebp.height}
                                width={SmallBecomeKnowerFrameWebp.width}
                                lazyLoading
                            />
                        </div>
                    ) : (
                        <div className="front">
                            <NextImage
                                src={BecomeKnowerFrameWebp}
                                alt="become knower frame"
                                height={BecomeKnowerFrameWebp.height}
                                width={BecomeKnowerFrameWebp.width}
                                lazyLoading
                            />
                        </div>
                    )}
                </PhoneWrapper>
            </Card>
        </Container>
    )
}

export default BecomeKnowerComponent
